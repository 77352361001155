@mixin mobile-s() {
    @media only screen and (max-width: 320px) {
        @content
    }
}

@mixin mobile-m() {
    @media only screen and (min-width: 375px) {
        @content
    }
}

@mixin mobile-l() {
    @media only screen and (min-width: 425px) {
        @content
    }
}

@mixin small() {
    @media only screen and (min-width: 640px) {
        @content
    }
}

@mixin until-medium() {
    @media only screen and (max-width: 768px) {
        @content
    }
}

@mixin tablet() {
    @media only screen and (min-width: 768px) {
        @content
    }
}

@mixin laptop() {
    @media only screen and (min-width: 1024px) {
        @content
    }
}

@mixin laptop-l() {
    @media only screen and (min-width: 1440px) {
        @content
    }
}
@mixin wide() {
    @media only screen and (min-width: 2560px) {
        @content
    }
}
