$background:   #f7f7f7 !default;
$box-shadow:   0px 1px 22px 4px rgba(0, 0, 0, 0.07);
$border:       1px solid rgba(191, 191, 191, 0.4);

/* Card sizing */
$card-height:  440px;
$card-width:   450px;
$inner-margin: 40px;
$stagger:      180px;
$outer-margin: 90px;
$marker-size:  9px;

/* Colors */
$timeline:     #bdbdbd !default;
$arrow-width:  35px !default;
$card-margin:  46px;
$padding:      80px;

/* Placeholders */
@mixin arrow($direction: left) {
    position:     absolute;
    content:      "";
    width:        $arrow-width;
    height:       $arrow-width;
    top:          50%;
    border-style: solid;

    @if ($direction == 'left') {
        transform: rotate(45deg) translate(-71%, 0%);
        left:      0;
        border-left-width:   1px;
        border-bottom-width: 1px;
        border-top-width:    0;
        border-right-width:  0;

    } @else {
        border-left-width:   0;
        border-bottom-width: 0;
        border-top-width:    1px;
        border-right-width:  1px;
        transform: rotate(45deg) translate(0%, -71%);
        right:     0;
    }
}

@mixin marker($direction) {
    position:         absolute;
    content:          "";
    box-shadow:       0 0 8px rgba(0, 0, 0, .25);
    width:            15px;
    height:           15px;
    border-radius:    50%;
    background-color: white;
    border:           1px solid darken(white, 20%);
    top:              50%;

    @if ($direction == 'right') {
        transform: translate(50%, -50%);
        left: 105%;

        @include wide()
        {
            left: 106.5%;
        }
    } @else {
        transform: translate(-50%, -50%);
        right: 105%;

        @include wide()
        {
            right: 106.5%;
        }
    }
}

#timeline {

    > h1, h2, h3 {
        text-align:    center;
        font-size:     3rem;
        font-weight:   200;
        margin-bottom: 20px;
    }

    > p {
        text-align:    center;
        max-width:     90%;
        margin:        auto;
        margin-bottom: 45px;
    }

    .card-wrapper {
        position:       relative;
        flex-direction: row;
        display:        flex;
        margin:         0 auto;
        flex-wrap:      wrap;
        justify-items:  center;
        align-items:    end;

        &::after {
            z-index:     1;
            content:     "";
            position:    absolute;
            bottom:      0;
            left:        50%;
            top:         ($padding / 2 + ($arrow-width/2));
            @include wide {
                top:         ($padding / 2 + ($arrow-width));
            }
            background-color: $timeline;
            width: 5px;
            transform: translate(-50%, 0);
        }
    }

    .card {
        width:         100%;
        position:      relative;
        display:       block;
        margin-bottom: $padding / 2;

        @include laptop() {

            &:nth-child(odd) {
                margin-right: 4%;

                .head::after {
                    @include arrow('right');
                }

                .head::before {
                    @include marker('right');
                }
            }

            &:nth-child(even) {
                margin-top:    $outer-margin * 2;
                margin-bottom: 0;
                margin-left:   4%;

                .head::after {
                    @include arrow('left');
                }

                .head::before {
                    @include marker('left');
                }
            }
        }

        @include laptop() {
            margin-bottom: $padding / 2;
            width:         46%;
        }

        z-index:       2;
        box-shadow:    $box-shadow;

        .head {
            position:       relative;
            display:        flex;
            align-items:    center;
            color:          #ffffff;
            font-weight:    400;
            padding:        $inner-margin / 2;
            flex-direction: column;

            @include wide() {
                padding:        $inner-margin;
                flex-direction: row;
            }

            .number-box {
                //position: absolute;
                //left: 0;
                //top: 0;
                width:         100%;
                margin-bottom: 1rem;
                display:       block;
                max-width:     100%;
                margin-right:  0;
                padding:       ($padding / 8) ($padding / 4);
                text-align:    right;
                font-weight:   600;
                background:    rgba(0, 0, 0, 0.5);
                font-size:     14px;

                @include wide() {
                    text-align:   left;
                    margin-right: $inner-margin;
                    display:      inline;
                    width:        auto;
                }
            }

            h2 {
                text-transform: uppercase;
                font-weight:    inherit;
                letter-spacing: 2px;
                margin:         0;
                padding-bottom: 6px;
                font-size:      20px;

                @include wide() {
                    font-size: 28px;
                }

                line-height:    1.7rem;
                width:          100%;

                span {
                    display:   block;
                    font-size: 0.6rem;
                    margin:    0;
                }
            }
        }

        .body {
            border:     $border;
            background: #ffffff;
            padding:    $inner-margin / 4;
            border-top: 0;

            @include tablet() {
                padding: $inner-margin / 2;
            }

            @include wide() {
                padding: $inner-margin;
            }

            > div {
                margin-bottom: $inner-margin;

                &:last-child {
                    margin: 0;
                }
            }

            img {
                border:    $border;
                display:   block;
                width:     100%;
                max-width: 635px;
                margin:    0 auto;
            }

            .btn {
                margin-right: 0;
            }
        }
    }
}

#timeline {
    .head::after {
        background-color: $background;
        border-color:     darken($background, 10%);
    }

    .head {
        background-color: $background;
        border:           1px solid darken($background, 10%);
    }
}


@mixin card-colors($color, $text: false) {

    @if lightness($color) > 40 {
        $text: darken($color, 35%);
    } @else {
        $text: lighten($color, 55%);
    }

    #timeline & .head::after {
        background-color: $color;
        border-color:     darken($color, 15%);
    }

    #timeline & .head {
        border-color:     darken($color, 15%);
        background-color: $color;

        h1, h2, h3, h4, h5 {
            color: $text;
            //color: darken($color, 35%);
        }
    }

    .btn {
        font-weight:   normal;
        border:        2px solid $color;
        color:         $text;
        border-radius: 0;
        margin-top:    20px;
    }
}

.blue {
    @include card-colors(#46b8e9);
}

.light-blue {
    @include card-colors(#8baed8);
}

.cyan {
    @include card-colors(#ce43eb);
}

.dark-blue {
    @include card-colors(#026ab3);
}


.lightgreen {
    @include card-colors(#3ee9d1);
}

.dark {
    @include card-colors(#4f4f4f);
}

#timeline .card-page {

    .body > div:nth-child(3) {
        text-align: right;
    }
}

#timeline .people {
    @include horizontal(1);
    @include vertical-padding();

    @include mobile-m() {
        @include horizontal(2);
    }

    @include tablet() {
        @include horizontal(4);
    }

    > div {
        text-align:  center;
        font-weight: bold;
    }
}