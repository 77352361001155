$primary-color:    #026ab3;
$primary-color-bg: #e5e5e5;
$borderLight:      #cccccc;
$borderDark:       #333333;

//$blue-dark:        #026ab3;
$blue-dark:        #014c81;
$button-color:     #014c81;
$blue:             #8baed8;
$blue-light:       #dde5f5;

$gradient-light: darken($blue, 20%);
$gradient-dark: darken($blue-dark, 10%);

$font-black:       #4f4f4f;

$color:            (
                           'main': ($primary-color, darken($primary-color, 10%), lighten($primary-color, 10%)),
                           'headline': (#4f4f4f, darken(#4f4f4f, 10%), lighten(#4f4f4f, 10%)),
                           'gray': (#666666 #333333 #999999),
                           'bg': ($primary-color-bg, darken($primary-color-bg, 10%), #f7f7f7),
                           'bg-text': (lighten($primary-color-bg, 20%), darken($primary-color-bg, 20%), lighten($primary-color-bg, 40%)),
                           'nav': (#4f4f4f, darken(#4f4f4f, 20%), lighten(#4f4f4f, 10%)),
                   );

$text-shadows:     (
                           banner: 1px 1px 1px white,
                           small: 1px 1px 1px black,
                           large: 1px 1px 3px lighten(black, 15%)
                   );

$micro:            'x';
$small:            's';
$medium:           'm';
$large:            'l';
$wide:             'w';

$grid-breakpoints: (
                           '' : 1px,
                           $micro: 200px,
                           $small: 320px,
                           $medium: 640px,
                           $large: 1024px,
                           $wide: 1200px
                   );

$row-max-width:    1240px;

$font-size:        16px;
$line-height:      $font-size * 1.6;
$font-family:      'PT Sans', sans-serif;
$background:       #f7f7f7;
$padding:          80px;
