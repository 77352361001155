$font-family: "Open Sans", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif !default;
$font-size: 19px !default;
$line-height: $font-size * 1.5 !default;

// Heading sizes
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td,
body,
html {
    margin:  0;
    padding: 0;
}

p {
}

ul, ol{
    margin: .8rem 0 .8rem 2rem;
}

li {
    padding: .3rem .5rem .2rem 0;
}

html, body {
    font-family: $font-family;
    font-size:   $font-size;
    box-sizing: border-box;
    line-height: normal;
}

p {
    margin-bottom: 1.5rem;
    font-size: inherit;
    line-height: $line-height;
}

em,
i {
    font-style: italic;
    line-height: inherit;
}

// Strong text
strong,
b {
    font-weight: bold;
    line-height: inherit;
}

// Small text
small {
    font-size: $font-size * .8;
    line-height: inherit;
}


a {
    cursor: pointer;
}