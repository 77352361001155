$button-color: $blue-dark !default;

@mixin button() {
    //border:           1px solid rgba(255,255,255,.3);
    //background-color: $button-color;
    //@include linear-gradient(to left bottom, $gradient-light, $gradient-dark);
    @include linear-gradient(to left bottom, $gradient-light, darken($gradient-light, 18%));

    color:            rgba(255,255,255,.8);
    display:          inline-block;
    font-weight:      bold;
    min-width:        150px;
    padding:          10px;
    text-align:       center;
    transition:       all .5s;
    //text-shadow: 0 0 3px rgba(0,0,0,.3);

    &:hover {
        color:            white;
        //background-color: lighten($button-color, 5%);
        box-shadow:       0 0 8px rgba(0, 0, 0, .3);
    }
}