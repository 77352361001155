h1, h2, h3, h4, h5, h6 {
    color: $font-black;

    @include tablet() {
        margin: 1.5rem 0 1.3rem 0;
    }

    header & {
        color: white;
    }
}

h1 {
    font-size: 3rem;

    @include laptop() {
        font-size: 3.5rem;
    }
}

h2 {
    font-size: 2.3rem;
    @include laptop() {
        font-size: 3rem;
    }

}

h3 {
    font-size: 2.0rem;

    @include laptop() {
        font-size: 2.5rem;
    }
}

h4 {
    font-size: 1.4rem;

    @include laptop() {
        font-size: 2rem;
    }
}

h5 {
    font-size: 1.3rem;

    @include laptop() {
        font-size: 1.6rem;
    }
}

h6 {
    font-size: 1.2rem;

    @include laptop() {
        font-size: 1.6rem;
    }
}
