#iconbar {
    position:         absolute;
    left:             0;
    bottom:           0;
    padding:          0;
    width:            100%;
    z-index:          600;
    background-color: transparent;
}

#logo {
    display:           inline-block;
    background-image:  url("./images/logo.jpg");
    background-size:   contain;
    background-repeat: no-repeat;
    width:             150px; // 0,368
    height:            55.2px;
    padding:           0;
    margin:     20px 0;

    @include laptop() {
        box-shadow:    none;
        margin:        0;
    }

    .small & {
        //height: 25px;
        //width: 68px;
    }
}

nav {
    transform:   translateX(-100%);
    display:     block;
    max-width:   100%;
    box-shadow:  0 0 8px rgba(0, 0, 0, .5);
    color:       color('bg');
    z-index:     600;
    position:    fixed;
    top:         0;
    left:        0;
    transition:  all .5s;
    min-height:  100%;

    @include linear-gradient(to right top, $gradient-light, $gradient-dark);

    @include laptop() {
        background:       none;
        background-color: white;
        border-bottom:    2px solid $blue-dark;
    }

    &.small {
        > div:nth-child(2) {

            @include laptop() {
                > div {
                    padding: 0;
                }

                a {
                    //font-size: 10px;
                    //padding: 5px;
                }
            }
        }
    }

    > div:nth-child(2) {

        // align logo in first row
        > div:first-child {
            background-color: white;
            text-align: center;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            box-shadow: 0 0 8px black;
            @include laptop() {
                box-shadow: none;
            }
        }

        @include vertical(12) ;

        @include laptop() {
            @include horizontal(5) {
                text-align:      center;
                display:         flex;
                justify-content: center;
                transition:      all .8s;
                padding:         25px;
            }
        ;

            overflow:        visible;
            align-items:     stretch;
            max-width:       $row-max-width;
            justify-content: stretch;
        }
    }

    > div {

        // zweite Ebene
        > div > div {
            padding-left: $padding + 20px;

            > a > div {
                border-left:   1px solid rgba(255, 255, 255, .1);
                border-bottom: 1px solid rgba(255, 255, 255, .2);
                position:      absolute;
                top:           0;
                left:          0;
                transform:     translate(-100%, -50%);
                height:        100%;
                width:         20px;

                @include laptop() {
                    display: none;
                }
            }

            a {
                position:     relative;
                padding:      $padding/8;
                padding-left: 30px;
                text-align:   left;
            }

            @include horizontal(1, 'a') {
                padding: 10px 5px;
            }
        ;

            @include laptop() {
                justify-content:  center;
                padding:          10px;
                position:         absolute;
                bottom:           0;
                left:             0;
                transform:        translateY(100%);
                background-color: white;
                max-height:       none;
                display:          none;
                border-bottom:    2px solid $blue-dark;
                border-left:      2px solid $blue-dark;
                border-right:     2px solid $blue-dark;
                box-shadow:       0 10px 8px rgba(0, 0, 0, .3);

                a {
                    text-align: center;
                }
            }
        }

        // erste ebene
        > div {
            border-bottom: 1px solid rgba(0, 0, 0, .1);

            @include laptop() {
                position: relative;
                border:   none;

                a {
                    display:         flex;
                    justify-content: center;
                    align-items:     center;
                }

                &:hover {
                    > div {
                        display: flex;
                    }
                }

                &:last-child {
                    border: none;
                }

            }
        }

        // alle ebenen
        > div a {
            display:    block;
            text-align: left;
            color:      color('bg');
            transition: all .5s;
            padding:    ($padding / 4) ($padding);

            &:hover {
                text-shadow: 0 0 1px rgba(0, 0, 0, .6);

                @include laptop() {
                    color:            $blue-dark;
                    background-color: transparent;
                    text-shadow:      none;
                }
            }

            &.btn {
                display: block;
                margin:  25px;

                @include laptop() {
                    margin:  0;
                    display: flex;
                    border:  none;
                    .fa-envelope, .fa-phone {
                        margin-right: 10px;
                    }
                }
            }

            @include laptop() {
                // override btn display explicit
                color:          #666666;
                font-size:      14px;
                padding:        14px;
                text-align:     center;
                text-shadow:    none;
                text-transform: uppercase;
                word-break:     break-word;
            }
        }
    }

    &.active {
        transform: translateX(0);
    }

    @include laptop() {
        padding:          0;
        background-color: white;
        display:          inline-block;
        transform:        none;
        width:            100%;
        min-height:       auto;
        height:           auto;

        #menubtn {
            display: none;
        }
    }
}

#menubtn {
    position:                   absolute;
    top:                        0;
    right:                      0;
    transform:                  translateX(100%);
    background-color:           $blue-dark;
    box-shadow:                 0 0 8px rgba(0, 0, 0, .3);
    border-bottom-right-radius: 5px;
    //border-bottom-left-radius: 5px;
    cursor:                     pointer;
    padding:                    8px;
    //box-shadow:       0 0 8px rgba(0, 0, 0, .6);
    transition:                 all .2s;
    width:                      25px;
    height:                     25px;

    > div {
        transition: all .5s;
    }

    .active & {
        transform:  translateX(0);
        background: transparent;
        box-shadow: none;

        .bar1, .bar2, .bar3 {
            background-color: $blue-dark;
        }

        .bar1 {
            margin:    0;
            transform: rotate(-45deg) translate(-25%, 200%);
        }

        .bar2 {
            opacity: 0;
        }

        .bar3 {
            margin:    0;
            transform: rotate(45deg) translate(-12.5%, -125%);
        }
    }

    > span {
        display:   none;
        height:    100%;
        font-size: 2rem;
        @include tablet() {
            display: inline-block;
        }
    }

    > div {
        width:            100%;
        height:           3px;
        background-color: white;
        margin:           4px 0;
    }

}

footer {
    box-shadow:       0 0 8px rgba(0, 0, 0, .3);
    border-top:       5px solid $gradient-light;
    background-image: linear-gradient(to left bottom, rgba(64, 121, 189, .85), rgba(1, 46, 78, .85)), url('./images/footer_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include horizontal(1) {
        @include horizontal(1);
        justify-content: center;
    }

    box-sizing:       border-box;
    background-color: #ffffff;
    padding:          $padding / 4;
    justify-content:  center;
    align-items:      center;

    @include tablet() {
        @include vertical() {
            @include horizontal(2);
            justify-content: center;
            padding:         $padding / 2;
        }
    }

    > div:first-child > div {

        &:last-child {
            padding-top: $padding;
            padding-bottom: $padding;
            @include tablet() {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        text-align: center;
        color:      rgba(255, 255, 255, .7);

        .fa-map-marker-alt, .fa-user-friends {
            font-size: 30px;
        }

        h3 {
            padding: 20px 0;
        }

        h3, a, i {
            color: rgba(255, 255, 255, .7);
        }
    }

    > div:last-child > div {

        display:         flex;
        justify-content: center;
        align-items:     center;

        &:nth-child(1) {
            @include horizontal(2);
            align-items:     center;
            justify-content: center;

            @include tablet() {
                align-items:     center;
                justify-content: flex-end;
            }

            a {
                padding:     1rem;
                font-weight: bold;
                color:       rgba(255, 255, 255, .3);

                &:hover {
                    color: rgba(255, 255, 255, .8);
                }
            }
        }

        &:nth-child(2) {
            color: rgba(255, 255, 255, .3);

            span {
                transition: color .8s;
            }

            &:hover {
                > span {
                    font-weight: bold;
                    color:       rgba(255, 255, 255, .8);
                }

                > span:nth-child(1) {
                    transition: all .5s;
                }

                > span:nth-child(2) {
                    transition: all .8s;
                }

                > span:nth-child(3) {
                    transition: all 1.2s;
                }

                > span:nth-child(4) {
                    transition: all 3s;
                }
            }

        }
    }
}
