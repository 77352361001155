@import '../settings';
@import '../../patternlab/mixin/text_shadows';
@import '../../patternlab/mixin/align';

header {
    position:            relative;
    text-align:          center;
    min-height:          400px;

    @include laptop() {
        padding-top:         64px;
    }

    &.no-image {
        @include vertical();

        justify-items:       center;
        justify-content:     center;
        display:             flex;
        background-repeat:   no-repeat;
        background-position: center center;
        background-size:     cover;
        max-height:          none;
        @include linear-gradient(to right top, $gradient-dark, $gradient-light);

        h2 {
            position:      relative;
            border-bottom: none;

            &:after {
                transform:  translate(-5%, 0);
                content:    "";
                background: linear-gradient(to right, rgba(255, 255, 255, .5), rgba(255, 255, 255, 0));
                display:    block;
                height:     2px;
                width:      110%;
                position:   absolute;
                bottom:     0;
            }

            @include laptop() {
                padding: 15px 38px;
                display: inline-block;
            }
        }

        > div {
            text-align: center;
            @include laptop() {
                text-align: left;
                padding:    0;
            }
        }
    }

    div > div {
        a {
            @include button();
            margin-top: 20px;
            box-sizing: border-box;
            color:      white;
            width:      100%;
            padding:    $padding /4;

            @include mobile-m() {
                margin-top:  0;
                width:       auto;
                margin-left: 40px;
            }
        }
    }

    > div {
        box-sizing: border-box;
        position:    relative;
        width:      100%;
        text-align: left;
        color:      white;
        padding:    5px 0;

        @include tablet() {
            padding: 20px 40px;
        }

        @include laptop() {
            padding: 10px $padding;
        }

        > h2, > h3, > h4, > h5, > h6, > p {
            padding:     15px 40px;
            text-shadow: 0 0 5px rgba(0, 0, 0, .2);
        }

        > h2, > h3, > h4, > h5, > h6 {
            font-size: 20px;

            @include tablet() {
                font-size: 30px;
            }

            @include laptop-l() {
                font-size: 40px;
            }
        }

        > h2 {
            position:  relative;

            &:after {
                transform:  translate(-40px, 0);
                content:    "";
                background: linear-gradient(to right, rgba(255, 255, 255, .5), rgba(255, 255, 255, 0));
                display:    block;
                height:     2px;
                width:      110%;
                position:   absolute;
                bottom:     0;
            }
        }
    }

    &.bg {

        picture, picture > img {
            max-width: 100%;
            min-width: 100%;
            margin:    0 auto;
            padding: 0;
            width:   100%;
        }

        > div:nth-child(2) {
            max-width: 1240px;
            position:  absolute;
            top:       50%;
            left:      50%;
            transform: translate(-50%, -20%);
        }
    }

    > h1 {
        @include text-shadow('large');
        font-size: $font-size * 2;
        color:     white;

        @include tablet() {
            padding:   $padding / 2;
            font-size: $font-size * 3;
        }

        @include laptop() {
            padding:   $padding;
            font-size: $font-size * 3;
        }
    }
}