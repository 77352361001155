@import "../settings";

@mixin box-style {
    background-color: color('bg', 'light');
    border:           1px solid color('bg', 'dark');
}

@mixin section-style() {
    background-color: #ffffff;
}

body, html {
    height:     100%;
    box-sizing: border-box;
    margin:     0;
}

body {
    line-height: $line-height;

    > nav > div,
    > article,
    header > div
    {
        max-width: $row-max-width;
        margin:    0 auto;
    }

    color:       $font-black;
}


header {
    background-position: left;
}

section {
    overflow: hidden;

    // remove bottom margin from last child element in card body
    .body {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// general Layout
article {
    padding: 0;

    > section {
        padding: $padding / 4;

        @include tablet() {
            padding: $padding / 2;
        }

        @include laptop() {
            padding: $padding ($padding /2);
        }
    }
}


.btn {
    @include button();

    &.highlight {
        background-color: color("main");
        color:            color("bg");
    }
}

// Set default spacing for elements in flex rows/column
section.type {
    &.vertical {
        > div {
            padding-bottom: $padding;

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    &.horizontal {
        > div {
            padding-right: $padding;

            &:last-child {
                padding-right: 0;
            }
        }
    }
}

// list
section.type-text {
    @include horizontal();
    @include horizontal-padding();
    @include section-style();

    &.no-padding {
        padding-top:  $padding / 8;

        @include laptop() {
            padding-top: $padding / 4;
        }
    }

    border-left-width:  0;
    border-right-width: 0;
}

.type-text, .type-section {
    h1, h2, h3, h4 {
        margin: 24px 0 24px 0;

        @include tablet() {
            margin:    48px 0 24px 0;
        }
    }

    p {
        font-size:   18px;
        line-height: 1.5em;
    }
}

p {
    &:last-child {
        margin: 0;
    }
}

section.type-pages {
    @include vertical();
    @include vertical-padding();

    justify-content: space-evenly;

    @include tablet() {
        @include horizontal(2);
        @include horizontal-padding(2);
    }

    @include laptop() {
        @include horizontal(3);
        flex-wrap: wrap;
    }

    .page {
        .content {
            font-size: 18px;

            > h4 {
                font-size:     24px;
                margin-bottom: 24px;
            }
        }
    }

    img {
        width: 100%;
    }

    .no-image {
        width:  451px;
        height: 275px;
        @include linear-gradient(#4f4f4f, darken(#4f4f4f, 10%));
    }

    > div {
        @include laptop() {
            flex-grow: unset;
            padding:   $padding  / 2;
        }

        .page {
            @include vertical();


            > div:first-child {
                overflow:    hidden;
                border:      1px solid color('bg', 'dark');
                line-height: 0;
                flex-grow:   unset;
                flex-shrink: unset;
            }

            img {
                transition: transform 2s;
                width:      100%;
            }

            &:hover img {
                transform: scale(1.05);
            }

            > .body {
                @include box-style();
                background-color: white;
                padding:          $padding / 4;

                @include laptop() {
                    padding: $padding / 2;
                }

            }
        }
    }
}

// more btn / link
.cta {
    text-align: right;
    margin-top: $padding / 4;

    @include laptop() {
        margin-top: $padding / 2;
    }
}

// types
.type-section {
    @include vertical();

    > div:first-child {
        padding-bottom: $padding;
        > a {
            display: block;
            > img  {
                width: 100%;
            }
        }
    }

    > div:last-child {
        padding-bottom: 0;
    }

    @include tablet() {
        @include horizontal();
        align-items: center;

        > div:first-child {
            padding-right:  $padding / 2;
            padding-left:   0;
            padding-bottom: 0;
        }
        > div:last-child {
            padding-right: 0;
            padding-left:  $padding / 2;
        }
    }

    &.reverse {
        flex-direction: column-reverse;

        > div {
            padding-bottom: $padding;

            &:first-child {
                padding-bottom: 0;
            }
        }

        @include tablet() {
            flex-direction: row-reverse;
            padding-bottom: 0;


            > div:first-child {
                padding-left:  $padding / 2;
                padding-right: 0;
            }

            > div:last-child {
                padding-left:   0;
                padding-right:  $padding / 2;
                padding-bottom: 0;
            }
            &.row100 {
                flex-direction: column-reverse;

                > div {
                    padding: 0;

                    &:last-child {
                        padding-bottom: $padding;
                    }

                }
            }
        }
    }

    &.row100 {
        @include vertical(1);

        > div {
            padding: 0;

            &:first-child {
                padding-bottom: $padding;
            }
        }

    }

    &.row50_50 {

        > div:first-child {

            @include tablet() {
                @include col(6);
            }
        }

        > div:last-child {

            @include tablet() {
                @include col(6) {
                    max-width: 50%;
                }
            }
        }
    }

    &.row33_66 {
        & > div:first-child {
            max-width: 100%;

            @include tablet() {
                @include col(3)
            }
        }

        & > div:nth-child(2) {
            max-width: 100%;

            @include tablet() {
                @include col(9) {
                    max-width: percentage(12/9);
                }
            }
        }
    }

    justify-content: center;
    font-size:       16px;

    @include laptop() {
        font-size: 18px;
    }

    .team & {
        padding: ($padding / 4);

        @include wide() {
            padding: $padding
        }

        &:first-of-type {
            margin-top: 0;
        }
    }

    > div {
        overflow: hidden;

        &:first-child {
            text-align: center;

            @include tablet() {
                margin-left:   auto;
                margin-bottom: 0;
                width:         100%;
            }
        }

        &:nth-child(2) {
            @include tablet() {
                margin-bottom: 0;
                width:         100%;
            }

            .landingpage & {
                h1, h2, h3, h4, h5, h6 {
                    text-align: center;
                    @include tablet() {
                        text-align: left;
                    }
                }

                p {
                    display: none;

                    @include tablet() {
                        display: inline-block;
                    }
                }
            }
        }

        > img {
            transition: all .2s;
            max-height: 100%;
            max-width:  100%;
            width:      99%;
        }
    }

    p:last-of-type {
        margin: 0;
    }
}

article > .type-cta {
    @include vertical();
    @include section-style;
    @include vertical-padding();

    text-align: center;

    h2, h3, h4, h5 {
        margin-bottom: ($padding / 2);
    }


    > div {
        justify-content: center;

        @include tablet() {
            flex-direction: row;
        }

        .btn {
            margin-bottom: $padding / 4;
            margin-right:  0;
        }
    }
}

.type-headline {
    position:       relative;
    padding-bottom: 0;
    text-align:     center;

    > h2 {
        color: $blue-dark;

    }
    > a {
        position: absolute;
        top:      -50px
    }


}

.type-employee {
    @include horizontal(2);
    @include horizontal-padding() ;

    justify-content: center;

    > div {
        @include vertical;

        flex-grow:  unset;
        text-align: center;
        font-size:  14px;

        > div {
            padding: 20px;
            @include box-style;

            > div {
                &:nth-child(3) {
                    font-weight: bold;
                    font-size:   15px;
                }

            }
        }

        img {
            border-radius: 50%;
            border:        1px solid #999999;
            max-width:     100%;
        }
    }
}

.type-col2 {
    @include vertical();
    @include vertical-padding();

    @include tablet() {
        @include horizontal(2);
        @include horizontal-padding();

        align-items: flex-start;
    }
}

.type-col3 {
    @include vertical();
    @include vertical-padding();

    @include tablet() {
        @include horizontal(3);
        @include horizontal-padding();

        align-items: flex-start;
    }
}

.type-participiant {
    @include horizontal();
    @include horizontal-padding();

    justify-content: center;
    flex-wrap:       wrap;
    padding:         0 ($padding / 2);

    > div {
        background-color: color('bg');
        margin:           0 ($padding /2) ($padding /2) ($padding /2);
        padding:          20px;
        width:            240px;
        flex-grow:        unset;
        border:           1px solid color('bg', 'dark');
        text-align:       center;
        font-size:        14px;

        > div {
            &:nth-child(3) {
                font-weight:   bold;
                font-size:     15px;
                margin-bottom: 10px;
            }

            &:nth-child(1) {
                text-align:    right;
                font-size:     12px;
                margin-bottom: 20px;
                color:         color('nav', 'light');
            }

            &:nth-child(2) {
                width:         200px;
                height:        200px;
                margin-bottom: 20px;
            }
        }

        img {
            border-radius: 50%;
            border:        1px solid #999999;
        }
    }
}

.type-iframe {
    text-align: center;
    overflow:   hidden;

    iframe {
        border-width: 0;
        max-width:    100%;
        max-height:   100%;
    }
}

.type-contact {

    > div {
        &:nth-child(1) {
            text-align:    center;
            margin-bottom: $padding / 4;

            @include tablet() {
                margin-bottom: $padding / 2;
            }

            a {
                padding:       $padding / 8;
                margin:        $padding / 8;
                border:        1px solid $primary-color;
                border-radius: 6px;
                display:       inline-block;

            }
        }
    }

    .contacts {
        @include horizontal(1);

        @include mobile-m() {
            @include horizontal(2);
        }

        h5 {
            color: $font-black;
        }

        justify-content: space-around !important;

        > div {
            margin-bottom: $padding / 4;
            text-align:    center;
            position:      relative;

            @include tablet {
                margin-bottom: $padding / 2;
            }

            @include laptop {
                margin-bottom: $padding;
            }

            > div:last-child {
                padding: $padding / 4 0;
            }

            img {
                max-width:     80%;
                @include tablet() {
                    max-width: 60%;
                }
                border-radius: 50%;
            }
        }
    }

    .account {

        @include vertical();
        @include vertical-padding();

        text-align: center;

        .fas {
            margin-right: 10px;
        }

        h5 {
            font-size: 1.5rem;

            @include mobile-l() {
                font-size: 2rem;
            }

            @include tablet() {
                font-size: 2.5rem;
            }
        }
    }
}

.type-pages_ref {
    color: $font-black;

    > div > p {
        margin-top: $padding / 4;
    }

    h2 {
        color:     $font-black;
        font-size: 36px;

        @include tablet() {
            font-size: 42px;
        }
    }

    > div:nth-child(1) {
        margin-top:    $padding / 2;
        margin-bottom: $padding / 2;

        @include tablet() {
            margin-bottom: $padding;
        }
    }

    .items {
        @include horizontal(1);

        @include tablet() {
            @include horizontal(2);
        }

        @include laptop() {
            @include horizontal(4);
        }
        align-items: stretch;

        .page {
            text-align:      center;

            img {
                height:        120px;
                width:         120px;
                margin-bottom: $padding /2;
            }

            p {
                text-align: left;
            }

            @include horizontal(1);
            padding:         10px;
            justify-content: flex-start;

            > div {
                background-color: #e7e7e7;

                &:first-child {
                    padding: 40px;
                    @include laptop() {
                        padding: 40px 20px 20px 20px;
                    }

                    h2, h3, h4, h5, h6 {
                        padding-bottom: 40px;
                    }
                }

                &:last-child {
                    display:         flex;
                    justify-content: center;
                    align-items:     flex-end;
                    padding:         20px 10px 40px 10px;
                    text-align:      center;
                }
            }

            margin-bottom:   3%;
        }

        h5 {
            text-align: center;
            color:      $blue-dark;
            z-index:    99;
            left:       0;
            padding:    10px 0;
            font-size:  $font-size * 1.3;
        }
    }
}