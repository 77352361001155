$text-shadows: (
   white: 1px 1px 1px white,
   small: 1px 1px 1px black,
   large: 1px 1px 3px lighten(black, 15%)
) !default;

@mixin text-shadow($size: "small") {

    @if (map_has_key($text-shadows, $size) == false) {
        @error "Key [#{$size}] not found in $text-shadows"
    }

    text-shadow: map_get($text-shadows, $size)
}