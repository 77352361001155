$grid-columns: 12 !default;

@mixin col($width: 1) {
    flex-basis: percentage($width/12);
    @content;
}

@mixin horizontal($rows: 0, $selector: "div") {
    max-width:      100%;
    max-height:     100%;
    overflow:       hidden;
    display:        flex;
    flex-direction: row;
    align-items:    stretch;
    flex-wrap:      wrap;

    @if ($rows > 0) {
        justify-content: space-between;
    }

    > #{$selector} {
        box-sizing: border-box;

        @if ($rows > 0) {
            @include col(12/$rows) {
                max-width: percentage((12/$rows)/12);
                display:    inline-block;
            }
        }

        @content;
    }
}

@mixin vertical-padding($nth: 1) {
    > div {
        padding-bottom: $padding / 4;
        padding-right:  0;
        padding-left:   0;

        @include tablet() {
            padding-bottom: $padding / 2;
        }

        @include laptop() {
            padding-bottom: $padding;
        }

        @if ($nth > 1) {

            &:nth-child(#{$nth}n+0) {
                padding-left: $padding / 4;

                @include tablet() {
                    padding-bottom: $padding / 2;
                }

                @include laptop() {
                    padding-bottom: $padding;
                }
            }
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

@mixin horizontal-padding($nth: 1) {
    > div {
        padding-right:  $padding / 4;
        padding-bottom: 0;

        @include laptop() {
            padding-right: $padding / 2;
        }

        @if ($nth > 1) {
            padding-bottom: $padding / 4;
            @include laptop() {
                padding-bottom: $padding / 2;
            }

            @include wide() {
                padding-bottom: $padding;
            }

            &:nth-child(#{$nth}n+0) {
                padding: 0;

                @include laptop() {
                }
            }
        }

        &:last-child {
            padding-right: 0;
        }
    }
}

@mixin vertical($cols: 0, $selector: "div") {
    max-width:      100%;
    max-height:     100%;
    overflow:       hidden;
    display:        flex;
    flex-direction: column;
    align-items:    stretch;
    flex-wrap:      wrap;

    > #{$selector} {
        box-sizing: border-box;

        @if ($cols > 0) {
            @include col(12/$cols) {
                display:    inline-block;

                @content ;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        @content;
    }
}

%vertical {
    flex-direction: column;
}

%multiline {
    flex-wrap: wrap;
}

.horizontal, .vertical {
    @include horizontal();
}

.vertical {
    @include vertical();
}

.multiline {
    @extend %multiline;
}

.horizontal {
    flex-direction: row;
}